.body1StyleSPan {
  color: #ff4b04;
}
.body1StylepFont {
  font-size: 0.28rem;
  padding-top: 0.5rem;
}
/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
  border: 0px;
  width: 60%;
}
.bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.bodyStyleDivStyle {
  border-bottom: 1px solid #fbfbfd;
  padding-bottom: 0.3rem;
}
.body1Style {
  padding: 0.3rem;
}
.bottom1 {
  margin-top: 2rem;
}
.whole {
  background: #ffffff;
}
.layoutBox {
  height: calc(100vh - 1.76rem);
  padding: 0.1rem 0.3rem;
}
